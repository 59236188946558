import React, { useState } from "react"
import ProductCard from "./ProductCard"
import ProductDetailModal from "./ProductDetailModal"

const Products = ({ products, campaignSlug }) => {
  const [selectedDetailProduct, setSelectedDetailProduct] = useState()

  const isDetailModalOpen = !!selectedDetailProduct
  return (
    <>
      <ProductDetailModal
        product={selectedDetailProduct}
        isOpen={isDetailModalOpen}
        onExit={() => setSelectedDetailProduct()}
        campaign={campaignSlug}
      />
      <div className="relative grid grid-cols-1 gap-5 px-8 lg:grid-cols-3 md:grid-cols-2 md:px-4">
        {products.map((product, i) => {
          return (
            <ProductCard
              key={product.sku}
              product={product}
              campaign={campaignSlug}
              onViewDetailSelect={(product) =>
                setSelectedDetailProduct(product)
              }
            />
          )
        })}
      </div>
    </>
  )
}

export default Products
