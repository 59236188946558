import React from "react"
import { navigate, Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Navigation = () => {
  const logo = useStaticQuery(graphql`
    query {
      sanityHome(title: { eq: "Landing" }) {
        headerLogo {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `)

  return (
    <nav className="absolute top-0 left-0 z-10 flex items-center justify-between w-full p-6">
      <Link to="/">
        <Img
          className="w-40"
          fluid={logo.sanityHome.headerLogo.asset.fluid}
          alt="Fundraisify logo"
        />
      </Link>
      <div className="block lg:flex lg:items-center lg:w-auto">
        <button
          className="inline-block px-4 py-2 text-sm leading-none text-white border border-white rounded hover:border-transparent hover:text-indigo-500 hover:bg-white lg:mt-0"
          onClick={() => navigate("/cart")}
        >
          <svg
            className="inline-block mr-2 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              className="heroicon-ui"
              d="M17 16a3 3 0 1 1-2.83 2H9.83a3 3 0 1 1-5.62-.1A3 3 0 0 1 5 12V4H3a1 1 0 1 1 0-2h3a1 1 0 0 1 1 1v1h14a1 1 0 0 1 .9 1.45l-4 8a1 1 0 0 1-.9.55H5a1 1 0 0 0 0 2h12zM7 12h9.38l3-6H7v6zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
            />
          </svg>
          Checkout
        </button>
      </div>
    </nav>
  )
}

export default Navigation
