import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Products from "../components/campaign/products/Products"
import Heading from "../components/Heading"
import Navigation from "../components/Navigation"
import "./campaign.css"
import Footer from "../components/common/Footer"
import { CampaignContext } from "../context/CampaignContext"
import CampaignPartners from "../components/campaign/CampaignPartners"

const Campaign = ({ data, pageContext }) => {
  const { updateCampaign } = useContext(CampaignContext)
  const { slug } = pageContext
  const { sanityCampaign, site } = data
  const {
    title,
    subtitle,
    logo,
    background,
    description,
    socialMedia,
    vendor,
  } = sanityCampaign

  useEffect(() => {
    updateCampaign(slug)
  }, [slug, updateCampaign])

  const campaignMetadata = {
    title,
    subtitle,
    description,
    socialMedia,
  }

  return (
    <>
      <SEO
        pageTitle={title}
        ogTitle={`Fundraisify | ${title}`}
        description={subtitle}
        ogImage={`/og-image/${slug}.png`}
        pathname={`/${slug}`}
      />
      <Navigation siteTitle={site.siteMetadata.title} />
      <main>
        <Heading
          title={title}
          subtitle={subtitle}
          background={background}
          logo={logo}
        />
        <section className="container py-16 mx-auto md:py-24">
          <CampaignPartners campaign={campaignMetadata} vendor={vendor} />
        </section>
        <section className="relative z-10 bg-gray-200">
          <div className="container py-16 mx-auto md:pb-32">
            <Products campaignSlug={slug} products={vendor.products} />
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    sanityCampaign(slug: { current: { eq: $slug } }) {
      title
      subtitle
      description
      socialMedia {
        facebook
        instagram
        twitter
        website
      }
      background {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
      logo {
        asset {
          fluid(maxWidth: 250) {
            ...GatsbySanityImageFluid
          }
        }
      }
      vendor {
        name
        description
        socialMedia {
          facebook
          instagram
          twitter
          website
        }
        products {
          sku
          price
          stripeReferenceId
          title
          subtitle
          description
          image {
            asset {
              fluid(maxWidth: 350, maxHeight: 350) {
                ...GatsbySanityImageFluid
              }
            }
          }
          options {
            key
            label
            possibleValues
          }
        }
      }
    }
  }
`
export default Campaign
