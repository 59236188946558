import React, { useState, useContext } from "react"
import { formatPrice } from "../../../utils/currency.util"
import GatsbyImage from "gatsby-image"
import AddToCartButton from "./AddToCartButton"
import { CartContext } from "../../../context/CartContext"
import { useCart } from "../../common/hooks/useCart"
import Alert from "../../common/Alert"
import { navigate } from "gatsby"

const ProductCard = ({ product, campaign, onViewDetailSelect }) => {
  const [showDetailsText, setShowDetailsText] = useState(false)
  const { cartId } = useContext(CartContext)
  const { addItem } = useCart(cartId)
  const [status, setStatus] = useState("INITIALIZED")

  const onAddToCart = async (product) => {
    setStatus("LOADING")
    try {
      await addItem(product, campaign)
      setStatus("SUCCESS")
    } catch (e) {
      console.error("Unable to add item to cart", e)
      setStatus("ERROR")
    }

    //TODO: cancel this timeout when component is unmounted
    setTimeout(() => {
      setStatus("INITIALIZED")
    }, 2000)
  }

  return (
    <>
      {status === "SUCCESS" && (
        <Alert
          onClose={() => setStatus("INITIALIZED")}
          message="Successfully added!"
          type="SUCCESS"
          actionText="View Cart"
          onAction={() => navigate("/cart")}
        />
      )}
      {status === "ERROR" && (
        <Alert
          onClose={() => setStatus("INITIALIZED")}
          message="Unable to add item to cart. Please try again."
          type="ERROR"
        />
      )}
      <div
        key={product.sku}
        className="overflow-hidden bg-white rounded-lg shadow-lg"
      >
        <button
          className="relative w-full text-center duration-200 ease-in transform cursor-pointer hover:scale-95 hover:opacity-75 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue"
          aria-label="Open detail view"
          onClick={() => onViewDetailSelect(product)}
          onMouseEnter={() => setShowDetailsText(true)}
          onMouseLeave={() => setShowDetailsText(false)}
        >
          {showDetailsText && (
            <div className="absolute z-10 flex items-center justify-center w-full h-full">
              <div className="p-4 bg-gray-50">
                <span>View Details</span>
              </div>
            </div>
          )}
          <GatsbyImage
            fluid={product.image.asset.fluid}
            className="w-full "
            alt="Bag of coffee with Lift Off decal on the cover"
          />
        </button>
        <div className="flex flex-col p-6 md:p-8">
          <div className="py-4">
            <div className="text-xl font-bold">{product.title}</div>
            <p className="text-base text-gray-700">
              {formatPrice(product.price)}
            </p>
          </div>
          <span class="my-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1 md:hidden">
            <button
              type="button"
              class="inline-flex items-center justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              onClick={() => onViewDetailSelect(product)}
            >
              View Details
            </button>
          </span>
          <AddToCartButton
            product={product}
            status={status}
            onClick={onAddToCart}
          />
        </div>
      </div>
    </>
  )
}

export default ProductCard
