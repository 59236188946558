import React, { useRef, useEffect, useState, useContext } from "react"
import GatsbyImage from "gatsby-image"
import FocusTrap from "focus-trap-react"
import { formatPrice } from "../../../utils/currency.util"
import AddToCartButton from "./AddToCartButton"
import { useCart } from "../../common/hooks/useCart"
import { CartContext } from "../../../context/CartContext"
import Alert from "../../common/Alert"

const ProductDetailModal = ({ product, isOpen, campaign, onExit }) => {
  const { cartId } = useContext(CartContext)
  const { addItem } = useCart(cartId)
  const [status, setStatus] = useState("INITIALIZED")
  const titleRef = useRef()

  useEffect(() => {
    if (titleRef && isOpen) {
      titleRef.current.focus()
    }
  }, [titleRef, isOpen])

  const onAddToCart = async () => {
    setStatus("LOADING")
    try {
      await addItem(product, campaign)
      setStatus("SUCCESS")
    } catch (e) {
      console.error("Unable to add item to cart", e)
      setStatus("ERROR")
    }

    //TODO: cancel this timeout when component is unmounted
    setTimeout(() => {
      setStatus("INITIALIZED")
    }, 2000)
  }

  return (
    <>
      {isOpen && (
        <FocusTrap>
          <div class="fixed z-10 bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
            {status === "ERROR" && (
              <Alert
                onClose={() => setStatus("INITIALIZED")}
                message="Unable to add item to cart. Please try again."
                type="ERROR"
              />
            )}
            <div class="fixed inset-0 transition-opacity">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div
              class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                <GatsbyImage
                  fluid={product.image.asset.fluid}
                  className="w-full"
                  alt="Bag of coffee with Lift Off decal on the cover"
                />
                <div class="mt-3 text-center sm:mt-5">
                  <h3
                    tabIndex={-1}
                    ref={titleRef}
                    class="text-lg leading-6 font-medium text-gray-900 outline-none"
                    id="modal-headline"
                  >
                    {product.title}
                  </h3>
                  <div class="mt-2 flex justify-center items-center">
                    <p class="text-sm leading-5 text-gray-500">
                      {formatPrice(product.price)}
                    </p>
                    <p class="ml-2 text-sm leading-5 text-gray-500">
                      {product.subtitle}
                    </p>
                  </div>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500">
                      {product.description}
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <span class="flex flex-col w-full rounded-md shadow-sm sm:col-start-2">
                  <AddToCartButton
                    product={product}
                    status={status}
                    onClick={onAddToCart}
                  />
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                  <button
                    type="button"
                    class="inline-flex items-center justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={onExit}
                  >
                    Continue Shopping
                  </button>
                </span>
              </div>
            </div>
          </div>
        </FocusTrap>
      )}
    </>
  )
}

export default ProductDetailModal
