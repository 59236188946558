import React from "react"
import Img from "gatsby-image"

const Heading = ({ title, subtitle, logo, background }) => {
  return (
    <header className="relative p-16 bg-gray-900 lg:py-48 xl:py-56">
      <div className="relative z-10 flex flex-wrap items-center justify-center">
        <div className="py-6 text-center md:p-16">
          <h1 className="m-8 mb-4 text-4xl font-extrabold leading-10 tracking-tight text-gray-100 sm:text-5xl sm:leading-none md:text-6xl">
            {title}
          </h1>
          <p className="text-2xl leading-10 tracking-tight text-gray-100 md:text-4xl">
            {subtitle}
          </p>
        </div>
        <div className="flex items-center justify-center w-24 h-24 bg-white rounded-full md:h-48 md:w-48">
          <Img className="w-16 md:w-32" fluid={logo.asset.fluid} />
        </div>
      </div>
      <Img
        className="top-0 left-0 w-full h-full opacity-25"
        style={{ position: "absolute" }}
        fluid={background.asset.fluid}
      />
    </header>
  )
}

export default Heading
